export const environment = {
  production: false,
  functionsUrl: 'https://us-central1-fir-beneficios.cloudfunctions.net/',
  notificationChannel: 'notification_channel_dev',
  bucket: 'dev/',
  tables: {
    benefits: 'benefitsDev',
    categories: 'categoriesDev',
    chats: 'chatsDev',
    users: 'usersDev',
    events: 'eventsDev',
    news: 'newsDev',
    surveys: 'surveysDev',
    documents: 'documentsDev',
    videos: 'videosDev',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCptgFpgP_egexVfqMJPi8_IOMoMmMdLD0',
    authDomain: 'fir-beneficios.firebaseapp.com',
    projectId: 'fir-beneficios',
    storageBucket: 'fir-beneficios.appspot.com',
    messagingSenderId: '141118474171',
    appId: '1:141118474171:web:112fc98dfe2a5cb66e269e',
    measurementId: 'G-YB9TFXDD09',
  },
};
